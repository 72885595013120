import React, { Component } from 'react'
import { AuthUserContext, withAuthorization } from '../../services/Session';
import { withFirebase } from '../../services/Firebase';
import Container from '@material-ui/core/Container';
import { Card } from '@material-ui/core';

const RecipeImage = ({media}) => {
        if(media.images.length > 0){
            return(<img alt="" style={{maxHeight: '256px'}} src={media.images[0].url}></img>)
        } 
        return null
}
const RecipeListItem = ({ recipe }) => {
  //  const imageSrc = recipe.media.images[0]?.url || '/images/hero-bg.jpg'
    const recipePath = `/recipes/${recipe.id}`
    return (
        <Card style={{margin: '0.5em', padding: '1em', flexBasis: 'fill', flexDirection: 'column'}} >
            <div>
            <a href={recipePath} >{recipe.name}</a>
            </div>
            <div>
            <RecipeImage media={recipe.media} />    
            </div>        
        </Card>
    )
}
const RecipeList = ({ recipes }) => {
    const recipeListItems = recipes.map((item) => {
        return (<RecipeListItem recipe={item} key={item.id} />)
    })
    return (
        <>
            {recipeListItems}
        </>
    )
}


class Recipes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            recipes: []
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        const authUser = this.context
        this.unsubscribe = this.props.firebase.recipes(authUser.uid).onSnapshot(snapshot => {
            const data = snapshot.docs
            this.setState({
                loading: false,
                recipes: data.map(doc => { return { id: doc.id, ...doc.data() } })
            })
        })
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    render() {
        const { loading } = this.state;

        return (
            <Container maxWidth="md">
                {loading ?
                    <p className="loading-dots">Loading recipes</p> :
                    <RecipeList recipes={this.state.recipes} />
                }

            </Container>
        );
    }
}

Recipes.contextType = AuthUserContext;

export default withFirebase(withAuthorization()(Recipes));