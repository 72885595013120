import React from 'react';

const DirectionsBody = ({directions}) => {
    const listItems = directions.map((direction, index) =>
      <li key={index}>{direction.text}</li>
    );
    return (
      <ol>
        {listItems}
      </ol>
    );
};

const Directions = ({directions}) => {
  if ((Array.isArray(directions) && directions.length > 0) || (typeof directions === 'string' && directions)) {
    return (
      <div className="recipe-directions">
        <h3>Directions</h3>
        <DirectionsBody directions={directions} />
      </div>
    )
  }
  return null
}

export default Directions;