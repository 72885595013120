import React from 'react';
const Home = () => {
    return (
        <section id="home" data-parallax="scroll" data-image-src="images/hero-bg.jpg" data-natural-width="3000" data-natural-height="2000">
            <div className="overlay"></div>
            <div className="home-content">
                <div className="row contents">
                    <div className="home-content-left">

                        <h3 data-aos="fade-up">Welcome to Le Chef</h3>

                        <h1 data-aos="fade-up">Le Chef is your awesome kitchen companion.</h1>

                        <div className="buttons" data-aos="fade-up">
                            <a href="#download" className="smoothscroll button stroke">
                                <span className="icon-circle-down" aria-hidden="true"></span>
                        Download App</a>
                        </div>

                    </div>

                    <div className="home-image-right">
                        <img src="images/iphone-app-470.png"
                            alt=""
                            srcSet="images/iphone-app-470.png 1x, images/iphone-app-940.png 2x"
                            data-aos="fade-up"></img>
                    </div>
                </div>

            </div>

            <div className="home-scrolldown">
                <a href="#about" className="scroll-icon smoothscroll">
                    <span>Scroll Down</span>
                    <i className="icon-arrow-right" aria-hidden="true"></i>
                </a>
            </div>
        </section>)
}
export default Home