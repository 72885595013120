import React from 'react';
import {Waypoint} from 'react-waypoint'
const About = ({onEnter}) => {
  return (<section id="about">

    <div className="row about-intro">

      <div className="col-four">
        <h1 className="intro-header" data-aos="fade-up">About Le Chef</h1>
      </div>
      <div className="col-eight">
        <p className="lead" data-aos="fade-up">
        Le Chef provides you with a fully personalized cookbook. Add recipes from a website and it will be automagically added to your recipe list.        </p>
      </div>

    </div>

    <div className="row about-features">
      <Waypoint onEnter={onEnter} />
      <div className="features-list block-1-3 block-m-1-2 block-mob-full group">
        <div className="bgrid feature" data-aos="fade-up">
          <span className="icon"><i className="icon-paint-brush"></i></span>
          <div className="service-content">
            <h3>Stylish Design</h3>
            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
                    </p>
          </div>
        </div>

        <div className="bgrid feature" data-aos="fade-up">
          <span className="icon"><i className="icon-sliders"></i></span>
          <div className="service-content">
            <h3>Easy To Use</h3>
            <p>Simply share a recipe link with the App on your phone and we extract all relevant information from the recipe. Providing you with a clean recipe in seconds.</p>
          </div>

        </div>

        <div className="bgrid feature" data-aos="fade-up">

          <span className="icon"><i className="icon-gift"></i></span>

          <div className="service-content">
            <h3>Free of Ads</h3>
            <p>No adds in between. Allowing you to focus on the content</p>
          </div>

        </div>

      </div>

    </div>

    <div className="row about-how">

      <h1 className="intro-header" data-aos="fade-up">How Le Chef Works?</h1>

      <div className="about-how-content" data-aos="fade-up">
        <div className="about-how-steps block-1-2 block-tab-full group">

          <div className="bgrid step" data-item="1">
            <h3>Download</h3>
            <p>Download the app and enjoy the <span>Discover</span> section to get some inspiration </p>
          </div>

          <div className="bgrid step" data-item="2">
            <h3>Sign-Up</h3>
            <p>Create your account to save recommendations or start adding your own recipes</p>
          </div>

          <div className="bgrid step" data-item="3">
            <h3>Share</h3>
            <p>Add your favorite recipes from many different websites and share them with the App</p>
          </div>

          <div className="bgrid step" data-item="4">
            <h3>Cook</h3>
            <p>Enjoy creating a fabolous dish and sharing it with friends or family</p>
          </div>

        </div>
      </div>

    </div>
  </section>)
}
export default About  