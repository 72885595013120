import React from 'react';

const defaultPlaceholderUrl = "./images/hero-bg.jpg";
const PlaceholderStyle = {
  backgroundImage: `url(${defaultPlaceholderUrl})`
};

const PlaceholderImage = ({key, className, src, alt}) => {
	return <img 
						key={key}
						className={className}
						src={src || defaultPlaceholderUrl}
						alt={alt || "placeholder"}
					/>
}

export default PlaceholderImage
export { PlaceholderStyle }