import React, { useState } from 'react';
import Slider from 'react-slick';

import PlaceholderImage from './PlaceholderImage';

const RecipeGallery = ({recipe}) => {
	const [fullscreen, setFullscreen] = useState(false);

	if (recipe) {
		let images = []
		if (recipe.media && recipe.media.images && recipe.media.images.length > 0) {
			let mediaLinks = recipe.media.images
			images = mediaLinks.map((mediaLink, index) => {
				return <img
								key={index}
								className="recipe-img"
								src={mediaLink.url}
								alt={`recipe gallery by ${mediaLink.copyright || "unknown"}`}
							/>
			})
		} else {
			images = [<PlaceholderImage className="recipe-img" key="0" />]
		}

		if (images.length > 0) {
			const settings = {
				className: "gallery-slider",
				adaptiveHeight: true
			};
			return (
				<div 
					className={fullscreen ? "gallery fullscreen" : "gallery"} 
				>
					<Slider {...settings}>
						{images}
					</Slider>
					<small onClick={() => setFullscreen(!fullscreen)}>{fullscreen ? "Click to close full size image display" : "Click to dislay images in full size"}</small>
				</div>
			)
		}
	}
	return null
}

export default RecipeGallery;