import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAvu5qMxRFppzMQ40npleL51lHyl14x384",
  authDomain: "le-chef-prod.firebaseapp.com",
  databaseURL: "https://le-chef-prod.firebaseio.com",
  projectId: "le-chef-prod",
  storageBucket: "le-chef-prod.appspot.com",
  messagingSenderId: "558968572167",
  appId: "1:558968572167:web:de109a276ac217a8de5da2",
  measurementId: "G-56E75JY44P"
};
firebase.initializeApp(firebaseConfig);
class Firebase {
  constructor() {

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();
  }

  recipes = (userId) => this.db.collection('recipes').where('owner', '==', userId).orderBy('updatedAt', 'desc')
  createUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  signOut = () => this.auth.signOut();
  passwordReset = email => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

}
const db = firebase.firestore()
export function fetchRecipe(recipeId){
  return db.collection('recipes').doc(recipeId)
}


export default Firebase;