import React from 'react';
import Landing from './components/Landing/Landing'
import Imprint from './components/Imprint'
import Privacy from './components/Privacy'
import Recipes from './components/Recipes/Recipes'
import Recipe from './components/Recipes/Recipe'
import {
  Switch,
  Route
} from "react-router-dom";
import './App.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import SignInPage from './components/User/SignIn';
import {withAuthentication} from './services/Session'
const App = () => {
  AOS.init({
    offset: 200,
    duration: 600,
    easing: 'ease-in-sine',
    delay: 200,
    once: true,
    disable: 'mobile'
  });
  return (
    <>
      <Switch>
        <Route path="/login" component={SignInPage} />
        <Route path="/recipes/:id" component={Recipe} />
        <Route exact path="/recipes" component={Recipes} />
        <Route exact path="/imprint">
          <Imprint />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route path="/">
          <Landing />
        </Route>
      </Switch>

    </>
  );
}
export default withAuthentication(App);;
