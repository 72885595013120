import React, { useState } from 'react';
import Header from './Header'
import Home from './Home'
import About from './About'
import Download from './Download'
import Footer from './Footer'
import { Waypoint } from 'react-waypoint';

const Landing = () => {
    const [currentSection, setCurrentSection] = useState('home');
    const setHome = () => {
        const dest = 'home'
        if (currentSection !== dest) {
            setCurrentSection(dest)
        }
    }
    const setAbout = () => {
        const dest = 'about'
        if (currentSection !== dest) {
            setCurrentSection(dest)
        }
    }
    const setDownload = () => {
        const dest = 'download'
        if (currentSection !== dest) {
            setCurrentSection(dest)
        }
    }
    return (
        <>
            <Header currentSection={currentSection} />

            <Waypoint onEnter={setHome}/>
            <Home />
            <div style={{ paddingBottom: "18rem", backgroundColor: 'white' }} />

            <About onEnter={setAbout} />

            <Download />
            <Waypoint onEnter={setDownload} />
            <Footer />
        </>
    );
}
export default Landing;
