import React from 'react';
const Footer = () => {
  return (
    <footer>
      <div className="footer-main">
        <div className="row">

          <div className="col-three md-1-3 tab-full footer-info">

            <p>Le Chef. Your cookbook! </p>


          </div>


          <div className="col-two md-1-3 tab-1-2 mob-full footer-site-links">

            <h4>Site Links</h4>

            <ul className="list-links">
              <li><a href="/">Home</a></li>
              <li><a href="/imprint">Imprint</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
            </ul>

          </div>
        </div>
      </div>


      <div className="footer-bottom">

        <div className="row">

          <div className="col-twelve">
            <div className="copyright">
              <span>© Copyright Dazzle 2018.</span>
              <span>Design by <a href="http://www.styleshout.com/">styleshout</a></span>
            </div>

            <div id="go-top">
              <a className="smoothscroll" title="Back to Top" href="#top"><i className="icon-arrow-up"></i></a>
            </div>
          </div>

        </div>

      </div>

    </footer>
  )
}
export default Footer