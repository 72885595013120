import React from 'react';
const Download = () => {
    return (<section id="download">
  
      <div className="row">
        <div className="col-full">
          <h1 className="intro-header" data-aos="fade-up">Download Le Chef Now!</h1>
  
          <p className="lead" data-aos="fade-up">
            Available for iOS and Android </p>
  
          <ul className="download-badges">
     {/* eslint-disable-next-line */}
            {/* <li><a href="#" title="" className="badge-appstore" data-aos="fade-up">App Store</a></li> */}
     {/* eslint-disable-next-line */}
            <li><a href="https://play.google.com/store/apps/details?id=app.lechef" title="" className="badge-googleplay" data-aos="fade-up">Play Store</a></li>
          </ul>
  
        </div>
      </div>
  
    </section>)
  }
export default Download