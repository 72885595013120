import React, { useEffect, useState } from 'react'
import { withFirebase } from '../../services/Firebase';
import { useParams } from 'react-router-dom';
import { fetchRecipe } from '../../services/Firebase/firebase'
import Directions from './Directions';
import RecipeGallery from './RecipeGallery';
import Servings from './Servings'
import Paper from '@material-ui/core/Paper';
import ReactMarkdown from 'react-markdown'
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';

const Title = ({ text }) => {
    if (text) {
        return <h2 className="recipe-title">{text}</h2>;
    }
    return null
}

const Description = ({ text }) => {
    if (text) {
        return <p className="recipe-description">{text}</p>;
    }
    return null
}

const GeneralInformation = ({ time }) => {
    if (!time) {
        return null
    } else if (time.prep && time.cooking) {
        return (<p className="recipe-prep-time">
            <span className="prep-time"><i className="material-icons">timer</i>{`${time.prep} min`}</span><span
                className="cooking-time">{` + ${time.cooking} min`}</span>
        </p>)
    } else if (time.prep) {
        return <p className="recipe-prep-time"><span className="prep-time"><i
            className="material-icons">timer</i>{`${time.prep} min`}</span></p>
    } else if (time.cooking) {
        return <p className="recipe-prep-time"><span className="cooking-time"><i
            className="material-icons">timer</i>{`${time.cooking} min`}</span></p>
    }
    return null
}

const RecipeHeader = ({ name, description, time }) => {
    return (
        <div className="recipe-header">
            <Title text={name} />
            <Description text={description} />
            <GeneralInformation time={time} />
        </div>
    )
}

const RecipeFooter = ({ source }) => {
    if (source?.url) {
        return (<div style={{ marginBottom: '1em', marginTop: '1em', display: 'flex', justifyContent: 'flex-end' }}>

            <a href={source.url} target="_blank" rel="noopener noreferrer">Original Recipe <i
                className="material-icons">open_in_new</i></a>
            <br />
        </div>)
    }
    return null

}

const Ingredients = ({ servings, ingredients }) => {
    if (ingredients && ingredients.length > 0) {
        const ingredientList = ingredients.map((ingredient, index) => {
            return <li key={index}>{ingredient.quantity} {ingredient.unit} {ingredient.name}</li>
        })
        return (
            <div className="recipe-ingredients-wrapper">
                <h3>Ingredients</h3>
                <Servings servings={servings} />
                <ul className="recipe-ingredients">{ingredientList}</ul>
            </div>
        )
    }
    return null
}

const RecipeContent = ({recipe}) => {
    return (<><RecipeHeader
        name={recipe.name}
        description={recipe.description}
        time={recipe.time}/>
        <RecipeGallery recipe={recipe} />
        {
            recipe?.content ?
                <ReactMarkdown source={recipe.content} /> :
                <>
                    <Ingredients servings={recipe.servings} ingredients={recipe.ingredients} />
                    <Directions directions={recipe.instructions} />
                </>
        }

        <hr />
        <RecipeFooter source={recipe.source} /></>)
}

const Recipe = withWidth()(withFirebase((() => {
    const { id } = useParams()
    const [recipe, setRecipe] = useState({})
    useEffect(() => {
        fetchRecipe(id).get().then((docRef) => {
            const data = docRef.data()
            console.log(data);

            setRecipe({ id, ...data })
        })
    }, [id]);

    return (
        <Container maxWidth="md" className="main-content">
            <Hidden smDown>
                <Paper elevation={3} className="recipe-wrapper">
                    <RecipeContent recipe={recipe}></RecipeContent>
                </Paper>
            </Hidden>
            <Hidden mdUp>
                <div className="recipe-wrapper">
                    <RecipeContent recipe={recipe}></RecipeContent>
                </div>
            </Hidden>
        </Container>

    );
})));

export default Recipe