import React from 'react';
import { Link } from "react-scroll";



const Header = ({ currentSection }) => {
    const links = [
        "Home",
        "About",
        "Download"
    ]
    const navLinks = links.map((p, index) => {
        const lower = p.toLowerCase()
        if (lower === currentSection) {
            return (<li key={index} className="current"><Link smooth={true} to={lower} title={lower}>{p}</Link></li>)
        } else {
            return (<li key={index}><Link smooth={true} to={lower} title={lower}>{p}</Link></li>)
        }

    })
    return (
        <header id="header" className="row">

            <div className="header-logo">
                <a href="index.html">Le Chef</a>
            </div>

            <nav id="header-nav-wrap">
                <ul className="header-main-nav">
                    {navLinks}
                </ul>

                {/* <a href="#" title="sign-up" className="button button-primary cta">Sign Up</a> */}
            </nav>
        </header>
    )
}
export default Header